import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CustomLoader({ size, color }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={size || "2rem"} color={color || "primary"} />
    </Box>
  );
}
