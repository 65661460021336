import React, { useState, useEffect } from "react";
import "./SetPassword.scss";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
// import { Link } from "react-router-dom";
import * as Yup from "yup";

const ResetPassword = () => {
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [resetError, setResetError] = useState("");
	const [passwordValid, setPasswordValid] = useState({
		hasLower: false,
		hasUpper: false,
		hasNumber: false,
		hasSymbol: false,
		isLengthValid: false,
	});
	const handleInputPassword = (event) => {
		setPassword(event.target.value);
		validatePassword(event.target.value);
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const validatePassword = (value) => {
		const schema = Yup.object().shape({
			password: Yup.string()
				.required("Please enter your new password")
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}(?![\s])$/,
					"*Please make sure the password meets all of the criteria listed."
				),
		});

		schema.isValid({ password: value }).then((valid) => {
			setPasswordValid({
				hasLower: /[a-z]/.test(value),
				hasUpper: /[A-Z]/.test(value),
				hasNumber: /\d/.test(value),
				hasSymbol: /[!@#$%]/.test(value),
				isLengthValid: value.replace(/\s/g, "").length >= 12,
				isValid: valid,
			});
		});
	};

	const handleUpdatePassword = async (event) => {
		event.preventDefault();
		try {
			const token = window.location.search.split("=")[1];
			await Yup.object()
				.shape({
					password: Yup.string()
						.required("Please enter your new password")
						.matches(
							/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{12,}$/,
							"*Please make sure the password meets all of the criteria listed."
						),
				})
				.validate({ password });

			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/users/reset_password/${token}`,
				{
					newPassword: password,
					userType: "Admin",
				}
			);

			if (response.data) {
				window.location.href = "/app-users";
			}
		} catch (error) {
			if (error.response && error.response.data && error.response.data.error) {
				const errorMessage = error.response.data.error;
				if (errorMessage === "Invalid reset token") {
					setResetError("Invalid reset password link");
				}
			} else {
				setResetError(error.message);
			}
			if (!password) {
				setResetError("Please enter your new password");
			}
		}
	};

	useEffect(() => {
		document.title = "Set Password - Bar Friender";
	}, []);

	return (
		<>
			<div class="wrapper-all-set-password">
				<div className="first-half">
					<div class="logo">
						<div className="avatar-container">
							<span className="custom-avatar">M</span>
						</div>
					</div>

					<div className="title-body-wrapper">
						<div className="title">
							<p>Set Password</p>
						</div>
						<div class="title-body">
							<p>Pick something creative yet memorable</p>
						</div>
					</div>
				</div>

				<div class="field">
					<KeyIcon />
					<input
						type={showPassword ? "text" : "password"}
						name="password"
						id="password"
						placeholder="Password"
						onChange={handleInputPassword}
					/>
					<div className="material-icon" onClick={togglePasswordVisibility}>
						{showPassword ? (
							<VisibilityIcon className="visibility-icon" />
						) : (
							<VisibilityOffIcon className="visibility-off-icon" />
						)}
					</div>
				</div>
				{resetError && (
					<div className="error-message-set-password">{resetError}</div>
				)}
				<div style={{ paddingLeft: "22px", color: "grey", fontSize: "12px" }}>
					<div>
						At least 1 lowercase letter{" "}
						{passwordValid.hasLower && <span className="check-icon">✔️</span>}
					</div>
					<div>
						At least 1 UPPERCASE letter{" "}
						{passwordValid.hasUpper && <span className="check-icon">✔️</span>}
					</div>
					<div>
						At least 1 number{" "}
						{passwordValid.hasNumber && <span className="check-icon">✔️</span>}
					</div>
					<div>
						At least 12 characters{" "}
						{passwordValid.isLengthValid && (
							<span className="check-icon">✔️</span>
						)}
					</div>
					<div>
						At least one symbol (!@#$%){" "}
						{passwordValid.hasSymbol && <span className="check-icon">✔️</span>}
					</div>
				</div>

				<div className="button-container-set-password">
					{/* <Link to="/password-verify" className="links">
            <button className="back-button">Back</button>
          </Link> */}
					<button
						className="continue-button-set-password"
						onClick={handleUpdatePassword}
					>
						Continue
					</button>
				</div>
			</div>
		</>
	);
};

export default ResetPassword;
