import React, { useState, useEffect } from "react";
import "./PasswordReset.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 

const PasswordReset = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const {state} = useLocation();
  const isEmailSubmitted = state?.isEmailSubmitted;
  const prevEmail = state?.email;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/forgot_admin_password`,
        {
          email: email,
          userType: "Admin",
        }
      );
      if (response.data) {
   
        toast.success("Please check your email for instructions", {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate(`/sign-in`); 
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setResetError("*Please check your email address");
      } else {
        setResetError(error.response.data.message);
      }
    }
  };
  const handleContinueAndReset = () => {
    if (!email) {
      setResetError("*Please enter your email address");
      return;
    }
    handleResetPassword();
  };
  useEffect(() => {
    document.title = "Password Reset - Bar Friender";
  }, []);

  const handleBackButton = () => {
    navigate("/sign-in", {state: {
      email: prevEmail,
      isEmailSubmitted: isEmailSubmitted
    }})
  }

  return (
    <div className="container">
      <div className="wrapper-all">
        <div className="first-half">
          <div className="logo">
            <div className="avatar-container">
              <span className="custom-avatar">M</span>
            </div>
          </div>
          <div className="title-body-wrapper">
            <div className="title">
              <p>Password Reset</p>
            </div>
            <div className="title-body">
              <p>to access your Bar Friender account</p>
            </div>
          </div>
        </div>
        <div className="field">
          <MailOutlineIcon />
          <input
            type="text"
            name="email"
            id="userName"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="button-container-password">
            <button className="back-button" onClick={handleBackButton}>Back</button>

          <button className="continue-button" onClick={handleContinueAndReset}>
            Continue
          </button>
        </div>
        {resetError && <div className="error-message-reset">{resetError}</div>}
      </div>
    </div>
  );
};

export default PasswordReset;
