import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import CustomTable from "../../components/table/CustomTable";
import TopBar from "../../components/topbar/Topbar"
import { StateContext } from "../../Context/StateContext";
import { formatDate } from "../../utils/formatDate";
import "./Gifts.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { ToastContainer } from "react-toastify";

const columns = [
  { id: "giftID", label: "Gift ID", minWidth: 170 },
  { id: "giftAmount", label: "Gift Amount", minWidth: 170 },
  { id: "sender", label: "Sender", minWidth: 170 },
  { id: "recipient", label: "Recipient", minWidth: 170 },
  { id: "privacySetting", label: "Privacy Setting", minWidth: 170 },
  { id: "dateSent", label: "Date Sent", minWidth: 170 },
];

const Gifts = () => {
  const [preparecsv] = useState(true);
  const [allGifts, setAllGifts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [exportGiftData, setExportGiftData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [topPadding] = useState(true);
  const { setTopbarHeader, setExportData } = useContext(StateContext);
  setTopbarHeader('Gifts');
  setExportData(true);
  const [giftFile] = useState("gift");

  function formatDate(inputDateStr) {
    let inputDate = new Date(inputDateStr);
    if (isNaN(inputDate.getTime()) || !inputDateStr) {
      const randomDate = new Date(
        Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000
      );
  
      inputDate = randomDate;
    }
  
    const year = inputDate.getFullYear().toString();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const hours = (inputDate.getHours() % 12 || 12).toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";
  
    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;
  
    return formattedDate;
  }
  
 
  useEffect(() => {
    document.title = "Gifts - Bar Friender";
    const token = localStorage.getItem("authToken");
    async function fetchData() {
      try {
        if (token) {
          if (searchValue) setPageNumber(1)
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/gifts/all`, {
            headers: {
              Authorization: token
            }
          });
          console.log(response?.data?.data)
          const modifiedData = response?.data?.data?.map((item) => ({
            ...item,
            giftID: item?._id || '--',
            giftAmount: `$${item?.giftAmount}.00`,
            sender: item?.giftSender?.username|| "--",
            recipient: item?.giftRecipient?.username|| "--",
            dateSent: formatDate(item?.createdAt) || '--',
            senderData: item?.sender,
            recipientData: item?.recipient,
            privacySetting: "Public",
            senderData: item?.giftSender,
            recipientData: item?.giftRecipient
          }))
          setAllGifts(modifiedData);
          setTotalRecords(response?.data?.results);
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [pageNumber, rowsPerPage, searchValue]); 

  useEffect(() => {
    const fetchGiftData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (token) {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/gifts/all`, {
            headers: {
              Authorization: token
            }
          });
          const data = response?.data?.data?.map((gift) => ({
            "Gift ID": gift?._id || '',
            "Gift Amount": gift?.giftAmount || '',
            Sender: gift?.giftSender?.username || '',
            Recipient: gift?.giftRecipient?.username || '',
            "Privacy Setting": "Public",
            "Date Sent": formatDate(gift?.createdAt || ''),
          }));
          setExportGiftData(data);
        }
      } catch (error) {
        console.error('Error exporting data:', error);
      }
    };
  
    fetchGiftData();
  }, []);

  const handleExportToCSV = (event, done) => {
     try {
      done(true)
     } catch (error) {
       done(false);
     }
  };

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };


  return (
    
    <>
    <div className="home-container">
       <ToastContainer />
        <Navbar />
    
      <div className="content-container">
          <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
           {/* {!isSignInPage && <TopBar header="App Users" /> } */}
          </div>
          <div>
    <div className="main-content">
      <div className="topbar-container">
        <TopBar
          header="Gifts"
          onExportButtonClick={handleExportToCSV}
          prepareDataForExport={exportGiftData} 
          preparecsv={preparecsv}
          fname={giftFile}
          />
      </div>
    <div className="table-container1">
        <CustomTable
          columns={columns}
          data={allGifts}
          context="gift"
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalRecords={totalRecords}
          searchValue={searchValue}
          handleSearchChange={setSearchValue}
          topPadding={topPadding}
          loading={loading}
        />
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
      
  );
};

export default Gifts;