import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../components/table/CustomTable";
import TopBar from "../../components/topbar/Topbar";
import "./AdminUser.scss";
import { StateContext } from "../../Context/StateContext";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const columns = [
  { id: "fullName", label: "Full Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "lastActive", label: "Last Active", minWidth: 100 },
];

function formatDate(inputDateStr) {
  const inputDate = new Date(inputDateStr);
  const utcDate = new Date(inputDate.getTime() + inputDate.getTimezoneOffset() * 60000);
  const pacificTime = new Date(utcDate.getTime() - 7 * 60 * 60000);
  
  const year = pacificTime.getFullYear().toString();
  const month = (pacificTime.getMonth() + 1).toString().padStart(2, '0');
  const day = pacificTime.getDate().toString().padStart(2, '0');
  const hours = (pacificTime.getHours() % 12 || 12).toString().padStart(2, '0');
  const minutes = pacificTime.getMinutes().toString().padStart(2, '0');
  const ampm = pacificTime.getHours() >= 12 ? "PM" : "AM";
  
  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;
  
  return formattedDate;
}

const AdminUser = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [exportUserData, setExportUserData] = useState([]);
  const [preparecsv] = useState(true);
  const [selectedUserTab, setSelectedUserTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [addAdmin] = useState(true);
  const [adminuserFile] = useState("admin");
  const [tabWidth] = useState(true);

  const { setTopbarHeader, setExportData } = useContext(StateContext);
  setTopbarHeader('Admin Users');
  setExportData(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    document.title = "Admin Users - Bar Friender";
    const token = localStorage.getItem("authToken");
    async function fetchData() {
      try {
        if (token) {
          const tabNames = ['Active', 'Disabled'];
          const tabName = tabNames[selectedUserTab];
          if (searchValue) setPageNumber(1);
          setLoading(true)
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/users?page=${pageNumber}&per_page=${rowsPerPage}&search=${searchValue}&userType=Admin&userStatus=${tabName ? tabName : ''}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          const modifiedData = response?.data?.data?.users?.map((item) => ({
            ...item,
            _id: item._id,
            "fullName": item.firstName + " " + item.lastName,
            "phoneNo": item.phoneNo?.number || '',
            "verifiedUser": item?.verifiedUser,
            lastActive: item?.lastActive ? formatDate(item?.lastActive) : "--",
          }));
          setAllUsers(modifiedData);
          setTotalRecords(response?.data?.results);
        setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [pageNumber, rowsPerPage, searchValue, selectedUserTab]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/users/User?userType=Admin`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          let filteredData = response?.data?.data?.users;
          if (selectedUserTab === 0) {
            filteredData = filteredData.filter((user) => user.userStatus === 'Active');
          } else if (selectedUserTab === 1) {
            filteredData = filteredData.filter((user) => user.userStatus === 'Disabled');
          }
          const data = filteredData?.map((user) => ({
            "Full Name": `${user.firstName} ${user.lastName}`,
            Email: user?.email || "",
            lastActive: user?.lastActive ? formatDate(user?.lastActive) : "--",
          }));
          if (selectedUserTab === 1 && data.length === 0) {
            setExportUserData([{
              "Full Name": "",
              Email: "",
              lastActive: "",
            }]);
          } else {
            setExportUserData(data);
          }
        }
      } catch (error) {
        console.error("Error exporting data:", error);
      }
    };

    fetchUserData();
  }, [selectedUserTab]);

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  const handleExportToCSV = (event, done) => {
    try {
      done(true);
    } catch (error) {
      done(false);
    }
  };

  return (
    <>
      <div className="home-container">
        <Navbar />
        <div className="content-container">
          <Sidebar />
          <div className="main-content">
            <div className="topbar-container"></div>
            <div className="main-content">
              <div className="topbar-container">
                <TopBar
                  header="Admin Users"
                  onExportButtonClick={handleExportToCSV}
                  prepareDataForExport={exportUserData}
                  preparecsv={preparecsv}
                  addAdmin={addAdmin}
                  fname={adminuserFile}
                />
              </div>
              <div className="table-container">
                <CustomTable
                  columns={columns}
                  data={allUsers}
                  context="admin"
                  labels={['Active', 'Disabled']}
                  pageNumber={pageNumber}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  totalRecords={totalRecords}
                  changeTab={setSelectedUserTab}
                  searchValue={searchValue}
                  handleSearchChange={setSearchValue}
                  tabWidth={tabWidth}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUser;
