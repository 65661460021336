import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  useMediaQuery,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./GitMessageList.scss";
import { StateContext } from "../../Context/StateContext";
import Topbar from "../../components/topbar/Topbar";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "../../components/CustomLoader";

const GiftMessageList = () => {
  const { setTopbarHeader } = useContext(StateContext);
  const [warningDisplayed, setWarningDisplayed] = useState(false);
  const [preparecsv] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState([]);
  const [loading, setLoading] = useState(false);

  setTopbarHeader("Gift Message List");
  useEffect(() => {
    document.title = "Gift Message List - Bar Friender";
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        setLoading(true)
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/gifts/all_messages`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const modifiedData = response?.data?.giftMessages;
        setMessages(modifiedData);
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleAddMessage = () => {
    const newMessage = { _id: Math.random().toString(), message: "" };
    setNewMessage((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/gifts/message/${id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      }
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message._id !== id)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNewMessage = (e, id) => {
    const updatedMessages = newMessage.map((item) => {
      if (item._id === id) {
        return { ...item, message: e.target.value };
      }
      return item;
    });
    setNewMessage(updatedMessages);
  };

  const handleSaveMessage = async () => {
    const nonEmptyMessages = newMessage.filter(
      (item) => item.message.trim() !== ""
    );

    if (nonEmptyMessages?.length === 0) {

      setWarningDisplayed(true);
      alert("Please enter a message");
      return;
    }

    const messagesToSave = nonEmptyMessages.map((item) => item.message);

    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/gifts/add_messages`,
          { messages: messagesToSave },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Changes saved", {
            position: toast.POSITION.TOP_CENTER,
          });

          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/gifts/all_messages`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          const modifiedData = response?.data?.giftMessages;
          setMessages(modifiedData);

          setNewMessage([]);
          setWarningDisplayed(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const isSmallScreen = useMediaQuery('(min-width: 768px)');
  const isMediumScreen = useMediaQuery("(min-width: 1024px)");
  const boxWidth = isMediumScreen ? "60%" : "90%";

  return (
    <div className="home-container">
      {/* <ToastContainer /> */}
      <Navbar />

      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            {/* {!isSignInPage && <TopBar header="App Users" /> } */}
          </div>
          <div className="main-content">
            <div>
              <div className="topbar-container">
                <Topbar preparecsv={preparecsv} />
              </div>
              <Box width={boxWidth}>
                {loading ? <Box sx={{ width: '76%', display: 'flex', justifyContent: 'center', marginTop: 4 }}><CustomLoader /></Box> : messages?.map((item) => {
                  return (
                    <>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        ml={3}
                        mt={6}
                      >
                        <TextField
                          fullWidth
                          id="textField"
                          label="Message tag"
                          variant="outlined"
                          value={item?.message}
                        />
                        <IconButton className="delete-button">
                          <DeleteIcon onClick={() => handleDelete(item?._id)} />
                        </IconButton>
                      </Box>
                    </>
                  );
                })}
                {newMessage?.map((item) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      ml={3}
                      mt={6}
                    >
                      <TextField
                        fullWidth
                        id="textField"
                        label="Message tag"
                        variant="outlined"
                        onChange={(e) => handleNewMessage(e, item?._id)}
                      />
                      <IconButton className="delete-button"></IconButton>
                    </Box>
                  );
                })}
              </Box>
              <Box className="button-box">
                <Button
                  className="save-button"
                  variant="contained"
                  onClick={handleSaveMessage}
                  style={{ textTransform: "capitalize" }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="add-button"
                  onClick={handleAddMessage}
                  style={{ textTransform: "capitalize" }}
                >
                  Add One Liner
                </Button>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GiftMessageList;
