import React, { useState, useEffect, useContext } from "react";
import { Button, Box, TextField, Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import "./AddAdmin.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Topbar from "../../components/topbar/Topbar";
import { StateContext } from "../../Context/StateContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddAdmin = () => {
  const navigate = useNavigate();
  const [toastShown, setToastShown] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [emailErrorToastShown, setEmailErrorToastShown] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
  });
  const { setTopbarHeader } = useContext(StateContext);
  const [authToken, setAuthToken] = useState("");
  const [error, setError] = useState("");
  setTopbarHeader("Admin User / New User");
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    document.title = `New User - Bar Friender`;
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .label("firstName").matches(/^[A-Za-z]+$/, 'First Name should only contain letters'),
    lastName: Yup.string().required("Last Name is required").matches(/^[A-Za-z]+$/, 'Last Name should only contain letters'),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    status: Yup.string().required("User Status is required"),
  });
  console.log(error);
  const handleCreateAdmin = (values) => {
    if (authToken) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/users/admin_users`,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            userStatus: values.status,
            userType: "Admin",
            password: "Admin@1234567",
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          toast.success("Admin Added successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setToastShown(true);
          navigate("/admin-users");
        })
        .catch((error) => {
          console.error("Error creating user:", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            if (errorMessage.includes("Email is already in use")) {
              if (!emailErrorToastShown) {
                alert("Email is already in use. Please choose a different email.");
                setEmailErrorToastShown(true);
              }
            } else {
              setError(errorMessage);
            }
          } else {
            setError("An error occurred. Please try again later.");
          }
        })
        .finally(() => {
          setIsSaving(false); 
        });
      
    }
  };

  return (
    <>
      <div className="home-container">
        <Navbar />

        <div className="content-container">
          <Sidebar />
          <div className="main-content">
            <div className="topbar-container"></div>
            <div className="main-content">
              <div className="topbar-container">
                <Topbar />
              </div>
              <div className="main-card">
                <div className="card-wrapper">
                  <Container fluid>
                  <Formik
                      initialValues={formData}
                      validationSchema={validationSchema}
                      onSubmit={async (values) => {
                        try {
                          handleCreateAdmin({
                            ...values,
                          });
                        } catch (error) {
                          console.log({ error: error.message });
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        values,
                        touched,
                        errors,
                      }) => {
                        return (
                          <>
                            <Box className="card-header">
                              <Typography className="card-header-about">
                                About
                              </Typography>
                              <Button
                                variant="contained"
                                className="save-button"
                                onClick={handleSubmit}
                                disabled={isSaving} 
                                style={{ textTransform: "capitalize" }}
                              >
                                Save
                              </Button>
                            </Box>
                            <Box
                              className="form"
                              display="flex"
                              flexDirection="column"
                              pt="24px"
                            >
                              <TextField
                                id="outlined-select-currency"
                                label="First Name"
                                variant="outlined"
                                onChange={handleChange("firstName")}
                              ></TextField>
                              {errors["firstName"] && touched["firstName"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["firstName"]}
                                </span>
                              )}

                              <TextField
                                type="text"
                                name="lastName"
                                label="Last Name"
                                variant="outlined"
                                onChange={handleChange("lastName")}
                                className="mt-4"
                              />
                              {errors["lastName"] && touched["lastName"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["lastName"]}
                                </span>
                              )}

                              <TextField
                                type="text"
                                name="email"
                                label="Email"
                                variant="outlined"
                                onChange={handleChange("email")}
                                className="mt-4"
                              />
                              {errors["email"] && touched["email"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["email"]}
                                </span>
                              )}
                              <Box
                                sx={{ minWidth: 120 }}
                                style={{ marginTop: "25px" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    User Status
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="User Status"
                                    onChange={handleChange("status")}
                                  >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Disabled">
                                      Disabled
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>

                              {errors["status"] && touched["status"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["status"]}
                                </span>
                              )}
                            </Box>
                            </>
                        );
                      }}
                    </Formik>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
