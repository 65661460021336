import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Signin from "./pages/Signin/Sigin";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import SetPassword from "./pages/PasswordReset/SetPassword";
import UserList from "./pages/UserList/UserList";
import Gifts from "./pages/Gifts/Gifts";
import Gift from "./pages/Gift/Gift";
import Moderation from "./pages/Moderation/Moderation";
import GiftMessageList from "./pages/GiftMessageList/GiftMessageList";
import SystemSettings from "./pages/Settings/SystemSetting";
import AdminUser from "./pages/AdminUser/AdminUser";
import User from "./pages/User/User";
import AddAdmin from "./pages/AddAdmin/AddAdmin";
import EditAdmin from "./pages/EditAdmin/EditAdmin";
import PrivateRoutes from "./PrivateRoutes";
import ModerationUser from "./pages/ModerationUser/ModerationUser";
import { AppProvider } from "./Context/index.js";
import { NotFound } from "./pages/NotFound/NotFound";
import { ToastContainer } from "react-toastify";


function App() {
  const [authToken] = useState(localStorage.getItem("authToken"));

  const isAuthenticated = () => {
    return authToken !== null;
  };

  const SignInRoute = ({ element }) => {
    return isAuthenticated() ? <Navigate to="/app-users" replace /> : element;
  };

  return (
    <>
      <AppProvider>
      <ToastContainer />
        <Routes>
           <Route path="/" element={<Navigate to="/app-users" replace />} />
          <Route
            
            path="/app-users"
            element={<PrivateRoutes Component={UserList} />}
          />
          <Route
            path="/gifts"
            element={<PrivateRoutes Component={Gifts} />}
          />
          <Route
            path="/moderation"
            element={<PrivateRoutes Component={Moderation} />}
          />
          <Route
            path="/moderation/moderation-user/:id"
            element={<PrivateRoutes Component={ModerationUser} />}
          />
            <Route
            path="/gift-message-list"
            element={<PrivateRoutes Component={GiftMessageList} />}
          />
            <Route
            path="/gifts/gift/:id"
            element={<PrivateRoutes Component={Gift} />}
          />
          <Route path="/app-users/user/:id" element={<PrivateRoutes Component={User} />} />
          <Route
            path="/system-settings"
            element={<PrivateRoutes Component={SystemSettings} />}
          />
          <Route
            path="/admin-users"
            element={<PrivateRoutes Component={AdminUser} />}
          />
          
         
          <Route
            path="/admin-users/add-admin"
            element={<PrivateRoutes Component={AddAdmin} />}
          />
          <Route
            path="/admin-users/update-admin/:id"
            element={<PrivateRoutes Component={EditAdmin} />}
          />
           <Route
                path="*"
                element={<PrivateRoutes Component={NotFound} />} />
          <Route
            path="/sign-in"
            element={<SignInRoute element={<Signin />} />}
          />

          <Route
            path="/password-reset"
            element={<SignInRoute element={<PasswordReset />} />}
          />
        
          <Route
            path="/set-password"
            element={<SignInRoute element={<SetPassword />} />}
          />
          <Route
                path="*"
                element={<SignInRoute element={<NotFound />} />}
          />
        </Routes>

      </AppProvider>
    </>
  );
}

export default App;
