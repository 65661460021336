import React, { useState, useContext, useEffect } from "react";
import CustomTable from "../../components/table/CustomTable";
import TopBar from "../../components/topbar/Topbar";
import "./userList.scss";
import { StateContext } from "../../Context/StateContext";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { ToastContainer } from "react-toastify";

const columns = [
  { id: "fullName", label: "Full Name", minWidth: 170 },
  { id: "username", label: "Username", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "formattedPhoneNo", label: "Phone", minWidth: 170 },
  { id: "verifiedUser", label: "Verified User", minWidth: 170 },
  { id: "giftsSent", label: "Number of Gifts Sent", minWidth: 170 },
  { id: "giftsReceived", label: "Number of Gifts Received", minWidth: 170 },
  { id: "amountSent", label: "Amount Sent", minWidth: 170 },
  { id: "amountReceived", label: "Amount Received", minWidth: 170 },
  { id: "status", label: "User Status", minWidth: 170 },
  { id: "lastActive", label: "Last Active", minWidth: 170 },
  // {
  //   id: "useSensitiveInfo",
  //   label: "Use of Sensitive Information",
  //   minWidth: 170,
  // },
];

function formatDate(inputDateStr) {
  let inputDate = new Date(inputDateStr);
  if (isNaN(inputDate.getTime()) || !inputDateStr) {
    return "--";
  }

  const year = inputDate.getFullYear().toString();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const hours = (inputDate.getHours() % 12 || 12).toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;

  return formattedDate;
}

const UserList = () => {
  const { setTopbarHeader, setExportData } = useContext(StateContext);
  const [authToken, setAuthToken] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [exportUserData, setExportUserData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  const [preparecsv] = useState(true);
  const [appuserFile] = useState("appuser");
  const [icon, setIcon] = useState(true);
  const [topPadding] = useState(true);
  const [allUsers, setAllUsers] = useState([]); // Add this line to define allUsers and setAllUsers

  function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace("+1", "");

    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

    return formatted;
  }

  useEffect(() => {
    setTopbarHeader("App Users");
    setExportData(true);
  }, [setTopbarHeader, setExportData]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    document.title = "App Users - Bar Friender";
    const token = localStorage.getItem("authToken");

    async function fetchData() {
      try {
        if (token) {
          if (searchValue) setPageNumber(1);
          setLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/users?userType=User&isDeleted=false`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          console.log(response, "responsee----------------------");
          const modifiedData = response?.data?.data?.users?.map((item) => {
            const firstName = item?.firstName || "";
            const lastName = item?.lastName || "";
            const fullName = `${firstName} ${lastName}`.trim() || "";

            return {
              ...item,
              _id: item._id,
              fullName: fullName,
              phoneNo: item?.phoneNo.number || "",
              formattedPhoneNo: formatPhoneNumber(item?.phoneNo?.number || ""),
              giftsSent: `${item?.giftsSentCount}`,
              giftsReceived: `${item?.giftsReceivedCount}`,
              amountSent: item?.totalSentAmount
                ? `$${item?.totalSentAmount?.toFixed(2)}`
                : "$0.00",
              amountReceived: item?.totalReceivedAmount
                ? `$${item?.totalReceivedAmount?.toFixed(2)}`
                : "$0.00",
              verifiedUser: item?.verifiedUser,
              lastActive: formatDate(item?.lastActive) || "--",
              status: item?.userStatus,
              // useSensitiveInfo: item?.useSensitiveInfo,
            };
          });

          setAllUsers(modifiedData);
          setTotalRecords(response?.data?.results);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [pageNumber, rowsPerPage, searchValue]);

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/users/User?userType=User&isDeleted=false`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          const data = response?.data?.data?.users?.map((user) => {
            const firstName = user?.firstName || "";
            const lastName = user?.lastName || "";
            const fullName = `${firstName} ${lastName}`.trim() || "";

            return {
              "Full Name": fullName,
              Username: user?.username || "",
              Email: user?.email || "",
              Phone: user?.phoneNo?.number ? user?.phoneNo.number : "",
              "Verified User": user?.verifiedUser || "",
              "Number of Gifts Sent": user?.giftsSentCount
                ? user?.giftsSentCount
                : 0,
              "Number of Gifts Received": user?.giftsReceivedCount
                ? user?.giftsReceivedCount
                : 0,
              "Amount Sent": user?.totalSentAmount
                ? `$${user?.totalSentAmount?.toFixed(2)}`
                : "$0.00",
              "Amount Received": user?.totalReceivedAmount
                ? `$${user?.totalReceivedAmount?.toFixed(2)}`
                : "$0.00",
              "User Status": user?.userStatus,
              lastActive: formatDate(user?.lastActive) || "--",
              "Use of Sensitive Information": user?.useSensitiveInfo,
            };
          });
          setExportUserData(data);
        }
      } catch (error) {
        console.error("Error exporting data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleExportToCSV = (event, done) => {
    try {
      done(true);
    } catch (error) {
      done(false);
    }
  };

  const handleUpIcon = async (label, order) => {
    setIcon(!icon);
    const lowerLabel = label.toLowerCase();
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/users?page=1&per_page=10&userType=User&sort=${lowerLabel}&order=${order}`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    );
    const modifiedData = response?.data?.data?.users?.map((item) => ({
      ...item,
      _id: item._id,
      fullName: item.firstName + " " + item.lastName,
      phoneNo: item.phoneNo?.number || "",
      giftsSent: `$${item?.giftsSent}`,
      giftsReceived: `$${item?.giftsReceived}`,
      amountSent: `$0`,
      amountReceived: `$${item?.amountReceived}`,
      verifiedUser: item?.verifiedUser,
      createdAt: item?.createdAt,
      status: item?.userStatus,
      // createdAt: "1/1/2024 01:12PM PT",
    }));
    setAllUsers(modifiedData);
  };

  const handleDownIcon = async (label, order) => {
    setIcon(!icon);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/users?page=1&per_page=10&userType=User&sort=${label}&order=${order}`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    );
    const modifiedData = response?.data?.data?.users?.map((item) => ({
      ...item,
      _id: item._id,
      fullName: item.firstName + " " + item.lastName,
      phoneNo: item.phoneNo?.number || "",
      giftsSent: `$${item?.giftsSent}`,
      giftsReceived: `$${item?.giftsReceived}`,
      amountSent: `$0`,
      amountReceived: `$${item?.amountReceived}`,
      verifiedUser: item?.verifiedUser,
      createdAt: item?.createdAt,
      status: item?.userStatus,
      // createdAt: "1/1/2024 1:12PM PT",
    }));
    setAllUsers(modifiedData);
  };

  return (
    <>
      <div className="home-container">
        <ToastContainer />
        <Navbar />

        <div className="content-container-user">
          <Sidebar />
          <div className="main-content">
            <div className="topbar-container"></div>
            <div>
              <div className="main-content">
                <div className="topbar-container">
                  <TopBar
                    header="App Users"
                    onExportButtonClick={handleExportToCSV}
                    prepareDataForExport={exportUserData}
                    preparecsv={preparecsv}
                    fname={appuserFile}
                  />
                </div>
                <div className="table-container">
                  <CustomTable
                    columns={columns}
                    data={allUsers}
                    context="user"
                    pageNumber={pageNumber}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    totalRecords={totalRecords}
                    searchValue={searchValue}
                    handleSearchChange={setSearchValue}
                    icon={icon}
                    handleUpIcon={handleUpIcon}
                    handleDownIcon={handleDownIcon}
                    topPadding={topPadding}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
