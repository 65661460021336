import React, { useContext, useEffect, useState } from "react";
import "./SystemSetting.scss";
import { Box, TextField, useMediaQuery } from "@mui/material";
import { StateContext } from "../../Context/StateContext";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SystemSetting = () => {
  const [sendAdminEmailTo, setSendAdminEmailTo] = useState("");
  const [barFrienderFeePercentage, setBarFrienderFeePercentage] = useState("");
  const [settingError, setSettingError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const { setTopbarHeader } = useContext(StateContext);
  const [preparecsv] = useState(false);
  const [saveButton] = useState(true);
  setTopbarHeader("System Settings");

  useEffect(() => {
    document.title = "System Settings - Bar Friender";
    fetchSettings();
  }, []);

  const handleFieldClick = () => {
    setEditMode(!editMode);
  };

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem("authToken");

      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/settings`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const settingsData = response?.data?.data?.settings;

        if (Array.isArray(settingsData) && settingsData?.length > 0) {
          const firstSetting = settingsData[0];
          setSendAdminEmailTo(firstSetting.sendAdminEmailTo || "");
          setBarFrienderFeePercentage(
            firstSetting.barFrienderFeePercentage || ""
          );
        }
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const updateSettings = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/settings/654ddb46d240a192cc1a95bf`,
          {
            sendAdminEmailTo,
            barFrienderFeePercentage,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Changes saved", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.log(response.data);
        }
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleClickSave = () => {
    handleContinueClick(); // Validate fields before updating settings
    if (!settingError) {
      updateSettings();
      setEditMode(false);
    }
  };

  const handleContinueClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/;
    if (!sendAdminEmailTo) {
      setSettingError("*Please enter your email address");
    } else if (!emailRegex.test(sendAdminEmailTo)) {
      setSettingError("*Please check your email address");
    } else {
      setSettingError("");
    }
  };

  const isMediumScreen = useMediaQuery("(max-width: 768px)");
  const boxWidth = isMediumScreen ? "90%" : "40%";

  return (
    <>
      <div className="home-container">
        <Navbar />

        <div className="content-container">
          <Sidebar />
          <div className="main-content">
            <div className="topbar-container"></div>
            <div className="main-content">
              <Topbar
                header="System Settings"
                preparecsv={preparecsv}
                saveButton={saveButton}
                handleClickSave={handleClickSave}
              />
              <Box mt={4} ml={2} width={boxWidth}>
                <TextField
                  fullWidth
                  id="sendAdminEmailTo"
                  label="Send admin email to"
                  variant="outlined"
                  value={sendAdminEmailTo}
                  onChange={(e) => setSendAdminEmailTo(e.target.value)}
                  placeholder="Enter admin email"
                  InputProps={{
                    readOnly: !editMode,
                    onClick: handleFieldClick,
                  }}
                />
                
                <div style={{color:"red"}}>{settingError}</div>
                <TextField
                  style={{ marginTop: "10px" }}
                  fullWidth
                  id="barFrienderFeePercentage"
                  label="Bar Friender Fee Percentage"
                  variant="outlined"
                  value={barFrienderFeePercentage}
                  onChange={(e) => setBarFrienderFeePercentage(e.target.value)}
                  placeholder="Enter fee percentage"
                  InputProps={{
                    readOnly: !editMode,
                    onClick: handleFieldClick,
                  }}
                />
              
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemSetting;
