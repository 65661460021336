import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Button,
  OutlinedInput,
  Box,
  Typography,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import "./EditAdmin.scss";
import { useLocation,  useNavigate } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import { useContext } from "react";
import { StateContext } from "../../Context/StateContext";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as Yup from "yup";

const EditAdmin = () => {
  const navigate = useNavigate();
  const [editFields, setEditFields] = useState(false);
  let { setTopbarHeader, topbarHeader } = useContext(StateContext);
  const [preparecsv] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    userName: "",
    email: "",
    phone: "",
    useSensitiveInfo:"",
    status: "",
    verifiedUser: "",
    giftsSent: 0,
    giftsReceived: 0,
    amountReceived: "",
    balance: "",
    lastActive: "",
  });



  const CustomIcon = () => {
    return <ArrowDropDownIcon style={{ marginRight: "15px" }} />; // Replace with your desired icon component
  };

  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    useSensitiveInfo: false,
    status: false,
    lastActive: false,
  });

  console.log(editableFields);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      document.title = `${userData.firstName} ${userData.lastName} - Bar Friender`;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      topbarHeader = "Admin Users";
      setTopbarHeader(
        `${topbarHeader} / ${userData.firstName} ${userData.lastName}`
      );
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        userName: userData.username || "",
        email: userData.email || "",
        phone: userData.phoneNo || "",
        useSensitiveInfo: userData.useSensitiveInfo || "",
        status: userData.userStatus || "",
        verifiedUser: userData.verifiedUser || "",
        giftsSent: userData.giftsSent || 0,
        giftsReceived: userData.giftsReceived || 0,
        amountReceived: userData.amountReceived || "",
        balance: userData.balance || "",
        lastActive: userData.lastActive || "",
      });
    }
  }, [location.state]);

  const handleEditClick = (event) => {
    setEditFields(!editFields);
    if (editFields) {
      const userId = location.state.userData._id;

      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/users/${userId}/update`,
          {
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            email: formData?.email,
            phone: formData?.phone,
            useSensitiveInfo: formData.useSensitiveInfo || "",
            userStatus: formData?.status,
          },
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          toast.success("Changes saved successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/admin-users");
          setEditableFields({
            name: false,
            email: false,
            phone: false,
            useSensitiveInfo: false,
            userStatus: false,
            userType: false,
            lastActive: false,
          });
        })
        .catch((error) => {
          console.error("Error updating admin information:", error);
        });
    }
  };
  const myVariant = editFields ? undefined : "standard";
  const inputLabelStyle = {
    "&.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
      color: "black",
    },
  };
  const inputStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  };
  const formStyles = { marginTop: 2 };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName").matches(/^[A-Za-z]+$/, 'First Name should only contain letters'),
    lastName: Yup.string().matches(/^[A-Za-z]+$/, 'Last Name should only contain letters'),
    email: Yup.string()
      .email("Invalid email address"),
    status: Yup.string(),
    useSensitiveInfo: Yup.string(),
  });

  return (
    <>
      <div className="home-container">
        <Navbar />

        <div className="content-container">
          <Sidebar />
          <div className="main-content">
            <div className="topbar-container">
              {/* {!isSignInPage && <TopBar header="App Users" /> } */}
            </div>
            <div className="main-content">
              <div className="main-card">
                <div className="topbar-container">
                  <Topbar preparecsv={preparecsv} />
                </div>
                <div className="card-wrapper">
                  <Container fluid>
                  <Formik
                      initialValues={formData}
                      validationSchema={validationSchema}
                      onSubmit={async (values) => {
                        try {
                          handleEditClick({
                            ...values,
                          });
                        } catch (error) {
                          console.log({ error: error.message });
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        values,
                        touched,
                        errors,
                      }) => {
                        return (
                          <>
                    <Box className="card-header">
                      <Typography className="card-header-about">
                        About
                      </Typography>
                      <Button
                        variant={editFields ? "contained" : "outlined"}
                        className={editFields ? "save-button" : "edit-button"}
                        onClick={handleSubmit}
                      >
                        {editFields ? "Save" : "Edit"}
                      </Button>
                    </Box>

                    <Box
                      className="form"
                      display="flex"
                      flexDirection="column"
                      pt="24px"
                    >
                       <FormControl
                        variant={myVariant}
                        disabled={editFields ? false : true}
                        sx={formStyles}
                      >
                        <InputLabel
                          htmlFor="component-outlined"
                          sx={inputLabelStyle}
                        >
                          First Name
                        </InputLabel>
                        {!editFields && (
                          <Input
                            sx={inputStyles}
                            value={formData.firstName}
                            disableUnderline
                          />
                        )}
                        {editFields && (
                          <OutlinedInput
                            id="component-outlined"
                            defaultValue={formData.firstName}
                            label="Full Name"
                            onChange={(e) => {
                              handleChange("firstName")(e);
                              formData.firstName = e.target.value; 
                            }}
                          />
                        )}
                         {errors["firstName"] && touched["firstName"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["firstName"]}
                                </span>
                              )}
                      </FormControl>
                      <FormControl
                        variant={myVariant}
                        disabled={editFields ? false : true}
                        sx={formStyles}
                      >
                        <InputLabel
                          htmlFor="component-outlined"
                          sx={inputLabelStyle}
                        >
                          Last Name
                        </InputLabel>
                        {!editFields && (
                          <Input
                            sx={inputStyles}
                            value={formData.lastName}
                            disableUnderline
                          />
                        )}
                        {editFields && (
                          <OutlinedInput
                            id="component-outlined"
                            defaultValue={formData.lastName}
                            label="Full Name"
                            onChange={(e) => {
                              handleChange("lastName")(e);
                              formData.lastName = e.target.value; 
                            }}
                          />
                        )}
                         {errors["lastName"] && touched["lastName"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["lastName"]}
                                </span>
                              )}
                      </FormControl>

                      <FormControl
                        variant={myVariant}
                        disabled={editFields ? false : true}
                        sx={formStyles}
                      >
                        <InputLabel
                          htmlFor="component-outlined"
                          sx={inputLabelStyle}
                        >
                          Email
                        </InputLabel>
                        {!editFields && (
                          <Input
                            sx={inputStyles}
                            value={formData.email}
                            disableUnderline
                          />
                        )}
                        {editFields && (
                          <OutlinedInput
                            id="component-outlined"
                            defaultValue={formData.email}
                            label="Email"
                            onChange={(e) => {
                              handleChange("email")(e);
                              formData.email = e.target.value; 
                            }}
                          />
                        )}
                         {errors["email"] && touched["email"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["email"]}
                                </span>
                              )}
                      </FormControl>
                      <FormControl
                                variant={myVariant}
                                disabled={!editFields}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                >
                                Use of Sensitive Information
                                </InputLabel>
                                {!editFields ? (
                                  <Input
                                    sx={inputStyles}
                                    value={formData?.useSensitiveInfo}
                                    disableUnderline
                                  />
                                ) : (
                                  <Select
                                    id="component-outlined"
                                    value={formData?.useSensitiveInfo}
                                    onChange={(e) => {
                                      handleChange("useSensitiveInfo")(e);
                                      formData.useSensitiveInfo = e.target.value;
                                    }}
                                    label="Use of Sensitive Information"
                                    IconComponent={CustomIcon}
                                  >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">
                                      No
                                    </MenuItem>
                                 
                                  </Select>
                                )}
                                {errors["useSensitiveInfo"] && touched["useSensitiveInfo"] && (
                                  <span
                                    className="text-error"
                                    style={{ color: "red" }}
                                  >
                                    {errors["useSensitiveInfo"]}
                                  </span>
                                )}
                                 </FormControl>
                      <FormControl
                        variant={myVariant}
                        disabled={!editFields}
                        sx={formStyles}
                      >
                        <InputLabel
                          htmlFor="component-outlined"
                          sx={inputLabelStyle}
                        >
                          User Status
                        </InputLabel>
                        {!editFields ? (
                          <Input
                            sx={inputStyles}
                            value={formData?.status}
                            disableUnderline
                          />
                        ) : (
                          <Select
                            id="component-outlined"
                            value={formData?.status}
                            onChange={(e) => {
                              handleChange("status")(e);
                              formData.status = e.target.value;  
                            }}
                            label="User Status"
                            IconComponent={CustomIcon}
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Disabled">Disabled</MenuItem>
                          </Select>
                        )}
                         {errors["status"] && touched["status"] && (
                                <span className="text-error" style={{ color: 'red' }}>
                                  {errors["status"]}
                                </span>
                              )}
                      </FormControl>
                    </Box>
                    </>
                        );
                      }}
                    </Formik>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
