import React, { useState, createContext } from "react";
const StateContext = createContext({})
const StateProvider = ({ children }) => {
  const [topbarHeader, setTopbarHeader] = useState("");
  const [exportData, setExportData] = useState(false);
  const state = {
    topbarHeader,
    setTopbarHeader,
    exportData,
    setExportData
  }
  return(
    <StateContext.Provider value={state}>
      {children}
    </StateContext.Provider>
  )
}
export { StateContext,StateProvider }