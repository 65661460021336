import React, { useState, useEffect } from "react";
import "./signin.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Signin = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [showForgetPasswordButton, setShowForgetPasswordButton] =
    useState(false);
  const [loginError, setLoginError] = useState("");
  const { state } = useLocation();
  const emailSubmitted = state?.isEmailSubmitted;
  const userEmail = state?.useremail;
  const prevEmail = state?.email;
  const [email, setEmail] = useState(prevEmail);


  const handleSignIn = async (event) => {
    try {
      event.preventDefault();
      if (!password) {
        setLoginError("*Please enter your password");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/login`,
        {
          email: email,
          password: password,
          userType: "Admin",
        }
      );
      if (response.data.message === "Logged In Successfully") {
        localStorage.setItem("authToken", response.data.token);
        const userData = JSON.stringify(response.data.user);
        localStorage.setItem("userInfo", userData);
        window.location.href = "/app-users";
      } else {
        console.log("Login failed!");
      }
    } catch (error) {
      console.error("Error occurred during login:", error);

      if (error.response && error.response.data && error.response.data.error) {
        setLoginError("*Please check your email and password");
      } else {
        setLoginError("An error occurred. Please try again later.");
      }
    }
  };

  const handleContinueClick = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]+\w+$/;
    if (!email) {
      setLoginError("*Please enter your email address");
    } else if (!emailRegex.test(email)) {
      setLoginError("*Please check your email address");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/users/email`,
          {
            email: email,
          }
        );
  
        if (response.data.exists === true) {
          setLoginError("");
          setIsEmailSubmitted(true);
          setShowForgetPasswordButton(true);
        } else {
          setLoginError("*Please check your email address");
        }
      } catch (error) {
        console.error("Error occurred while checking email:", error);
      }
    }
  };
  
  

  console.log("error: email", loginError, email);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleForgetButton = () => {
    navigate("/password-reset", {
      state: {
        email: email,
        isEmailSubmitted: isEmailSubmitted,
      },
    });
  };

  useEffect(() => {
    document.title = "Sign In - Bar Friender";
  }, []);

  const emailBoxClick = () => {
    setIsEmailSubmitted(false);
    setShowForgetPasswordButton(false);
    navigate("/sign-in", {
      state: {
        useremail: email,
      },
    });
  };

  return (
    <div className="wrapper-all">
      <div className="first-half">
        <div className="logo">
          <div className="avatar-container">
            <span className="custom-avatar">M</span>
          </div>
        </div>
        <div className="title-body-wrapper">
          <div className="title">
            <p style={{ marginBottom: "4px" }}>Sign In</p>
          </div>

          <div className="title-body">
            {isEmailSubmitted || emailSubmitted ? (
              <div className="dropdown-show" onClick={emailBoxClick}>
                <button
                  className="custom-dropdown-button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {email || prevEmail}
                  <ArrowDropDownIcon className="arrow-icon" />
                </button>
              </div>
            ) : (
              <p>to your Bar Friender account</p>
            )}
          </div>
        </div>
      </div>

      {!isEmailSubmitted && !emailSubmitted ? (
        <div className="field">
          <MailOutlineIcon />
          <input
            type="text"
            name="email"
            defaultValue={userEmail}
            id="userName"
            placeholder="Email"
            onChange={handleInputChange}
          />
        </div>
      ) : null}

      {isEmailSubmitted || emailSubmitted ? (
        <div className="field">
          <KeyIcon className="key-icon" />
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            onChange={handleInputPassword}
          />
          <div className="material-icon" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <VisibilityIcon className="visibility-icon" />
            ) : (
              <VisibilityOffIcon className="visibility-off-icon" />
            )}
          </div>
        </div>
      ) : null}
      {loginError && <div className="error-message">{loginError}</div>}
      <div className="button-container">
        {showForgetPasswordButton || emailSubmitted ? (
          <button className="forgot-button" onClick={handleForgetButton}>
            Forgot Password
          </button>
        ) : null}
        {!isEmailSubmitted && !emailSubmitted ? (
          <button
            type="button"
            className="button-common"
            onClick={handleContinueClick}
          >
            Continue
          </button>
        ) : (
          <button
            type="button"
            className="signin-button-common"
            onClick={handleSignIn}
          >
            Sign In
          </button>
        )}
      </div>
    </div>
  );
};

export default Signin;
