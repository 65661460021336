import React from "react";
import "./Navbar.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
export default function PrimarySearchAppBar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userData = localStorage.getItem("userInfo");
  const finaluserData = JSON.parse(userData);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userInfo");
    window.location.href = "/sign-in";
  };

  const handleClickUser = () => {
    const userInfo = localStorage.getItem("userInfo");
    const result = JSON.parse(userInfo);
    navigate(`/app-users/user/${result._id}`, {
      state: {
        reportedByData: result
      }
    });
  }

  let finaluserName = `${finaluserData?.firstName} ${finaluserData?.lastName}`;
  let finalname = `${finaluserName.toUpperCase()}`
  return (
    <>
      <div className="navbar custom-navbar">
        <div className="left-nav">
          <div className="navbar-text-uon"><b>Bar Friender</b></div>
        </div>

        <div className="right-nav">
          <div className="menu-button">
            <button className="menu-button-style" onClick={handleClick}>
              {finalname}
              <ArrowDropDownIcon />
            </button>
          </div>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClickUser}>
          {finaluserData?.firstName} {finaluserData?.lastName}
        </MenuItem>
        <MenuItem onClick={handleClose}>{finaluserData?.email}</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
