import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const GetAllModeration = createAsyncThunk(
  "GetAllModeration/GetAllModeration",
  async ({ token }, {rejectWithValue}) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/moderation/all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          err: error.response,
          status: error.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetModerationsByReportStatus = createAsyncThunk(
  "GetModerationsByReportStatus/GetModerationsByReportStatus",
  async ({ token, status }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/moderation?&reportStatus=${status}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue({
          err: error.response,
          status: error.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const Moderation = createSlice({
  name: "Moderation",
  initialState: {
    allModerations: {},
   filteredModerations: {
      data: {
        reportItems: []
     },
     results: 0
    },
    error: null,
    allModerationLoading: false,
    filteredModerationLoading: false,

  },
  reducers: {
    setFilteredModerations(state, action) {
      state.filteredModerations = action?.payload || {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllModeration.pending, (state) => {
        state.allModerationLoading = true;
      })
      .addCase(GetAllModeration.fulfilled, (state, action) => {
        state.allModerationLoading = false;
        state.allModerations = action.payload || [];
      })
      .addCase(GetAllModeration.rejected, (state, action) => {
        state.allModerationLoading = false;
        state.error = action.error.message;
      })
      .addCase(GetModerationsByReportStatus.pending, (state) => {
        state.filteredModerationLoading = true;
      })
      .addCase(GetModerationsByReportStatus.fulfilled, (state, action) => {
        state.filteredModerationLoading = false;
        state.filteredModerations = action.payload || [];
      })
      .addCase(GetModerationsByReportStatus.rejected, (state, action) => {
        state.filteredModerationLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setFilteredModerations } = Moderation.actions;
export default Moderation.reducer;
