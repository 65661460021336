import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const ModerationCountContext = createContext({});

export const ModerationCountProvider = ({ children }) => {
  const [totalModeration, setTotalModeration] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/moderation`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          setTotalModeration(response.data.results);
        })
        .catch((error) => {
          console.error("Error fetching flagged items count:", error);
        });
    }
  }, []);

  return (
    <ModerationCountContext.Provider value={{ totalModeration }}>
      {children}
    </ModerationCountContext.Provider>
  );
};

export {ModerationCountContext};


