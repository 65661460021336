import React, { useState, useEffect } from "react";
import SimpleCard from "../../components/SimpleCard/SimpleCard";
import { Box, Button, Typography } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import { useContext } from "react";
import Topbar from "../../components/topbar/Topbar";
import { StateContext } from "../../Context/StateContext";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { ToastContainer } from "react-toastify";
import "./ModerationUser.scss";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModerationUser = () => {
	const location = useLocation();
	const [preparecsv] = useState(false);
	const [buttonOn, setButtonOn] = useState("");
	const [saveButton] = useState(true);
	let { setTopbarHeader, topbarHeader } = useContext(StateContext);
	const token = localStorage.getItem("authToken");
	const navigate = useNavigate();
	const [updatedBy, setUpdatedBy] = useState("");
	const [formData, setFormData] = useState({
		fullName: "",
		userName: "",
		email: "",
		phone: "",
		verifiedUser: "",
		giftsSent: 0,
		giftsReceived: 0,
		amountReceived: "",
		balance: "",
		lastActive: "",
		lastUpdated:"",
		location:""
	});

// 	function formatDate(inputDateStr) {
//     let inputDate = new Date(inputDateStr);
//     if (isNaN(inputDate.getTime()) || !inputDateStr) {
//         const randomDate = new Date(
//             Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000
//         );

//         inputDate = randomDate;
//     }

//     const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
//     const day = inputDate.getDate().toString().padStart(2, '0');
//     const hours = (inputDate.getHours() % 12 || 12).toString().padStart(2, '0');
//     const minutes = inputDate.getMinutes().toString().padStart(2, '0');
//     const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";

//     const formattedDate = `${month}/${day} ${hours}:${minutes} ${ampm} PT`;

//     return formattedDate;
// }

function formatDate(inputDateStr) {
	let inputDate = new Date(inputDateStr);
	if (isNaN(inputDate.getTime()) || !inputDateStr) {
			const randomDate = new Date(
					Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000
			);

			inputDate = randomDate;
	}

	const utcDate = new Date(inputDate.getTime() + inputDate.getTimezoneOffset() * 60000);
	const pacificTime = new Date(utcDate.getTime() - 7 * 60 * 60000); 

	console.log("UTC Date:", utcDate); // Check UTC date
	console.log("Pacific Time:", pacificTime); // Check Pacific Time

	const year = pacificTime.getFullYear().toString();
	const month = (pacificTime.getMonth() + 1).toString().padStart(2, '0');
	const day = pacificTime.getDate().toString().padStart(2, '0');
	const hours = (pacificTime.getHours() % 12 || 12).toString().padStart(2, '0');
	const minutes = pacificTime.getMinutes().toString().padStart(2, '0');
	const ampm = pacificTime.getHours() >= 12 ? "PM" : "AM";
	
	const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;
	
	console.log("Formatted Date:", formattedDate); // Check formatted date

	return formattedDate;
}

	

	useEffect(() => {
		topbarHeader = "Moderation";
		setTopbarHeader(`${topbarHeader} / Report Details`);
		if (location.state && location.state.userData) {
			const reportData = location.state.userData;
			document.title = `${reportData.reportedBy} - Bar Friender`;
			setFormData({
				id: reportData?._id,
				reportedBy: reportData?.reportedBy || "--",
				postedBy: reportData?.appUser || "--",
				itemReported: reportData?.reportType || "--",
				reason: reportData?.reason || "--",
				reportedDate: formatDate(reportData?.createdAt) || "--",
				status: reportData?.reportStatus || "--",
				lastUpdated:formatDate(reportData?.lastUpdated) || "--",
				location:reportData?.location || "--",
				reportedByData: reportData?.reportedByData,
				sourceAccountData: reportData?.sourceAccountData,
			});
			setButtonOn(reportData?.reportStatus);
			console.log(reportData.reportedByData, "reportedByData inside useEffect");
		}
	}, []);

	const buttonStyles = {
		borderRadius: "10px",
		backgroundColor: "transparent",
		color: "blue",
		border: "1px solid black",
		height: "20px",
		padding: "10px",
		textTransform: "none",
	};

	const getStatusButtonStyles = (status) => ({
		...buttonStyles,
		backgroundColor: formData.status === status ? "blue" : "white",
		color: formData.status === status ? "white" : "blue",
		"&:hover": {
			backgroundColor: formData.status === status ? "blue" : "white",
			color: formData.status === status ? "white" : "blue",
		},
	});

	const statusClick = (label) => {
		setButtonOn(label);
		const userData = localStorage.getItem("userInfo");
		const finalData = JSON.parse(userData);
		setUpdatedBy(finalData.firstName);
	};

	const handleClickSave = () => {
		const user = JSON.parse(localStorage.getItem("userInfo"));
		const userFullName = `${user.firstName} ${user.lastName}`;
		const currentTime = new Date();
		axios
			.patch(
				`${process.env.REACT_APP_BASE_URL}/moderation/${formData?.id}`,
				{
					reportStatus: buttonOn,
					location: userFullName,
					lastUpdated: currentTime,
				},
				{
					headers: {
						Authorization: `${token}`,
					},
				}
			)
			.then((response) => {
				toast.success("Status change saved", {
					position: toast.POSITION.TOP_CENTER,
				});
				navigate("/moderation");
			})
			.catch((error) => {
				console.error("Error updating flag status:", error);
			});
	};

	const onMenuClick = (number) => {
		if (number === "first") {
			navigate(`/app-users/user/${formData?.reportedByData?._id}`, {
				state: {
					reportedByData: formData?.reportedByData,
				},
			
			});
			console.log(formData.reportedByData, "reported by data-----------------------");
		}
		if (number === "second") {
			navigate(`/app-users/user/${formData?.reportedByData?._id}`, {
				state: {
					sourceAccountData: formData?.sourceAccountData,
				},
			});
		}
		if (number === "third") {
			navigate("/gifts");
		}
	};

	return (
		<>
			<div className="home-container">
				<ToastContainer />
				<Navbar />

				<div className="content-container">
					<Sidebar />
					<div className="main-content">
						<div className="topbar-container"></div>
						<div className="main-content">
							<div className="topbar-container">
								<Topbar
									preparecsv={preparecsv}
									saveButton={saveButton}
									handleClickSave={handleClickSave}
								/>
							</div>
							<div className="d-flex">
								<Box display="flex">
									<Container className="mt-5">
										<Row>
											<Col md={6} className="mb-3">
												<SimpleCard
													heading="Reported By"
													data={formData.reportedBy}
													icon={true}
													onMenuClick={() => onMenuClick("first")}
												/>
											</Col>
											<Col md={6} className="mb-3">
												<SimpleCard
													heading="Posted By"
													data={formData.postedBy}
													icon={true}
													onMenuClick={() => onMenuClick("second")}
												/>
											</Col>
										</Row>

										<Row>
											<Col md={6} className="mb-3 d-flex">
												<SimpleCard
													heading="Item Reported"
													data={formData.itemReported}
													icon={true}
													onMenuClick={() => onMenuClick("third")}
												/>
											</Col>
											<Col md={6} className="mb-3">
												<SimpleCard
													heading="Reason For Report"
													data={formData.reason}
												/>
											</Col>
										</Row>

										<Row>
											<Col md={6} className="mb-3">
												<SimpleCard
													heading="Reported On"
													data={formData.reportedDate}
												/>
											</Col>
											<Col md={6} className="mb-3">
												<SimpleCard
													heading="Status"
													data={
														<Box>
															<Box display="flex" justifyContent="space-evenly">
																<button
																	className={
																		buttonOn === "Pending Review"
																			? "onButton"
																			: "offButton"
																	}
																	onClick={() => statusClick("Pending Review")}
																>
																	Pending
																</button>
																<button
																	className={
																		buttonOn === "Ignored"
																			? "onButton"
																			: "offButton"
																	}
																	onClick={() => statusClick("Ignored")}
																>
																	Ignored
																</button>
																<button
																	className={
																		buttonOn === "Confirmed"
																			? "onButton"
																			: "offButton"
																	}
																	onClick={() => statusClick("Confirmed")}
																>
																	Confirmed
																</button>
															</Box>

															<Box pt={1}>
																<Typography style={{ fontSize: "15px" }}>
																	{updatedBy
																		? `Updated by ${formData?.location} on ${formData?.lastUpdated}`
																		: null}
																</Typography>
															</Box>
														</Box>
													}
												/>
											</Col>
										</Row>
									</Container>
								</Box>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModerationUser;
