import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  OutlinedInput,
  Box,
  Typography,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import { Container } from "react-bootstrap";
import "./User.scss";
import { StateContext } from "../../Context/StateContext";
import Topbar from "../../components/topbar/Topbar";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../components/modal/Modal";
import socketIO from "socket.io-client";

const socket = socketIO.connect("https://stagingbackend.bar-friender.com");

const User = () => {
  console.log(socket, "sockets----------------------");
  const navigate = useNavigate();
  const { id } = useParams();
  const [editFields, setEditFields] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { setTopbarHeader, topbarHeader } = useContext(StateContext);
  const [preparecsv] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [picture, setPicture] = useState();
  const [userHeight] = useState("user");
  const location = useLocation();
  const reportedByData = location.state?.reportedByData;
  const sourceAccountData = location.state?.sourceAccountData;

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  function formatDate(inputDateStr) {
    let inputDate = new Date(inputDateStr);
    if (isNaN(inputDate.getTime()) || !inputDateStr) {
      return "--";
    }

    const year = inputDate.getFullYear().toString();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const hours = (inputDate.getHours() % 12 || 12).toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;

    return formattedDate;
  }

  const [formData, setFormData] = useState({
    fullName: "",
    username: "",
    email: "",
    phone: "",
    useSensitiveInfo: "",
    status: "",
    verifiedUser: "",
    giftsSent: 0,
    giftsReceived: 0,
    amountReceived: "",
    lastActive: "",
  });

  const CustomIcon = () => {
    return <ArrowDropDownIcon style={{ marginRight: "15px" }} />;
  };

  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    useSensitiveInfo: false,
    status: false,
    userType: false,
    lastActive: false,
  });

  useEffect(() => {
    if (reportedByData) {
      setFormData({
        firstName: reportedByData.firstName || "",
        lastName: reportedByData.lastName || "",
        username: reportedByData.username || "",
        email: reportedByData.email || "",
        phone: reportedByData.phoneNo.number || "",
        useSensitiveInfo: reportedByData.useSensitiveInfo || "",
        status: reportedByData.userStatus || "",
        verifiedUser: reportedByData.verifiedUser || "",
        giftsSent: reportedByData.giftsSent || 0,
        giftsReceived: reportedByData.giftsReceived || 0,
        amountReceived: reportedByData.amountReceived || `$` + 0,
        amountSent: reportedByData?.amountSent || `$` + 0,
        lastActive: reportedByData?.lastActive,
      });
      setPicture(reportedByData.profilePicture);
    }
  }, [reportedByData]);

  useEffect(() => {
    if (sourceAccountData) {
      setFormData({
        firstName: sourceAccountData.firstName || "",
        lastName: sourceAccountData.lastName || "",
        username: sourceAccountData.username || "",
        email: sourceAccountData.email || "",
        phone: sourceAccountData.phoneNo.number || "",
        useSensitiveInfo: sourceAccountData.useSensitiveInfo || "",
        status: sourceAccountData.userStatus || "",
        verifiedUser: sourceAccountData.verifiedUser || "",
        giftsSent: sourceAccountData.giftsSent || 0,
        giftsReceived: sourceAccountData.giftsReceived || 0,
        amountReceived: sourceAccountData.amountReceived || `$` + 0,
        amountSent: sourceAccountData?.amountSent || `$` + 0,
        lastActive: sourceAccountData?.lastActive,
      });
      setPicture(sourceAccountData.profilePicture);
    }
  }, [sourceAccountData]);

  useEffect(() => {
    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData, "userData");
      document.title = `${userData.firstName} ${userData.lastName} - Bar Friender`;
      topbarHeader = "App Users";
      setTopbarHeader(
        `${topbarHeader} / ${userData.firstName} ${userData.lastName}`
      );
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        username: userData.username || "",
        email: userData.email || "",
        phone: userData.phoneNo || "",
        formattedPhoneNo: userData?.formattedPhoneNo || "",
        useSensitiveInfo: userData.useSensitiveInfo,
        status: userData.userStatus || "",
        verifiedUser: userData.verifiedUser || "",
        giftsSent: userData.giftsSent || 0,
        giftsReceived: userData.giftsReceived || 0,
        amountReceived: userData.amountReceived || `$` + 0,
        amountSent: userData?.amountSent || `$` + 0,

        lastActive: userData?.lastActive,
      });
      setPicture(userData?.profilePicture || "");
    }
  }, [location.state]);

  const handleEditClick = (event) => {
    setEditFields(!editFields);
    console.log(formData);
    if (editFields) {
      let userId = "";
      if (reportedByData) {
        userId = reportedByData._id;
      } else if (sourceAccountData) {
        userId = sourceAccountData._id;
      } else {
        userId = location.state.userData._id;
      }
      let phoneNumber = formData?.phone;
      if (!isNaN(phoneNumber)) {
        phoneNumber = `+1${phoneNumber}`;
      }

      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/users/${userId}/update`,
          {
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            username: formData?.username,
            email: formData?.email,
            phoneNo: { number: phoneNumber },
            useSensitiveInfo: formData?.useSensitiveInfo,
            userStatus: formData?.status,
          },
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          toast.success("Changes saved successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/app-users");
          setEditableFields({
            name: false,
            email: false,
            phone: false,
            useSensitiveInfo: false,
            status: false,
            userType: false,
            lastActive: false,
          });
        })
        .catch((error) => {
          console.error("Error updating admin information:", error);
        });
    }
  };

  const myVariant = editFields ? undefined : "standard";
  const inputLabelStyle = {
    "&.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
      color: "black",
    },
  };
  const inputStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  };
  const formStyles = { marginTop: 2 };

  const removeImage = async () => {
    try {
      const userData = location?.state?.userData;
      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/users/${id}/profile_picture`,
        {},
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (userData) {
        userData.profilePicture = "";
      }

      setPicture("");
      navigate(`/app-users/user/${id}`, { state: { userData } });
    } catch (error) {
      console.error("Error removing image:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .label("firstName")
      .matches(/^[A-Za-z]+$/, "First Name should only contain letters"),
    lastName: Yup.string().matches(
      /^[A-Za-z]+$/,
      "Last Name should only contain letters"
    ),
    email: Yup.string().email("Invalid email address"),
    username: Yup.string().label("username"),
    phone: Yup.string()
      .min(10, "Phone number must be at least 10 characters")
      .matches(/^(?!(\d)\1{9}$)\d{10}$/, "Invalid phone number")
      .matches(/^\d+$/, "Phone number should only contain digits"),
    status: Yup.string(),
    useSensitiveInfo: Yup.string(),
  });

  const handleDeleteClick = () => {
    console.log("in");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((response) => {
        toast.success("User deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        socket.emit("onLogout", { userId: id });
        navigate("/app-users");
      })
      .catch((error) => {
        console.error("Error updating admin information:", error);
      });
  };
  useEffect(() => {
    // Listen for the 'emitlogout' event acknowledgment
    socket.on("emitlogout", (data) => {
      console.log("Logout event acknowledged with data:", data);
      // Handle any response data as needed
    });
  }, [socket]);

  return (
    <>
      <div className="home-container">
        <Navbar />

        <div className="content-container">
          <Sidebar newHeight={userHeight} />
          <div className="main-content">
            <div className="topbar-container"></div>
            <div className="main-content">
              <div className="main-card">
                <div className="topbar-container">
                  <Topbar preparecsv={preparecsv} />
                </div>
                <div className="card-wrapper">
                  <Container fluid>
                    <Formik
                      initialValues={formData}
                      validationSchema={validationSchema}
                      onSubmit={async (values) => {
                        try {
                          handleEditClick({
                            ...values,
                          });
                        } catch (error) {
                          console.log({ error: error.message });
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        values,
                        touched,
                        errors,
                      }) => {
                        return (
                          <>
                            <Box className="card-header">
                              <Typography className="card-header-about">
                                About
                              </Typography>
                              <div className="button-gap">
                                <Button
                                  onClick={handleDeleteClick}
                                  className="deletebutton"
                                >
                                  Delete
                                </Button>

                                {isModalOpen && (
                                  <Modal
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                    onConfirm={handleConfirmDelete}
                                  />
                                )}

                                <Button
                                  variant={
                                    editFields ? "contained" : "outlined"
                                  }
                                  className={
                                    editFields ? "save-button" : "edit-button"
                                  }
                                  onClick={handleSubmit}
                                >
                                  {editFields ? "Save" : "Edit"}
                                </Button>
                              </div>
                            </Box>

                            <Box className="profile">
                              <Avatar
                                alt="Profile Picture"
                                src={picture}
                                className="avatar"
                              />
                              <Box
                                className="remove-button-box"
                                sx={{ paddingTop: "16px" }}
                              >
                                {editFields ? (
                                  <Button
                                    variant="contained"
                                    className="remove-button"
                                    onClick={removeImage}
                                  >
                                    Remove Image
                                  </Button>
                                ) : null}
                              </Box>
                            </Box>
                            <Box
                              className="form"
                              display="flex"
                              flexDirection="column"
                              pt="24px"
                            >
                              <FormControl
                                variant={myVariant}
                                disabled={editFields ? false : true}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  First Name
                                </InputLabel>
                                {!editFields && (
                                  <Input
                                    sx={inputStyles}
                                    value={formData.firstName}
                                    disableUnderline
                                  />
                                )}
                                {editFields && (
                                  <OutlinedInput
                                    id="component-outlined"
                                    defaultValue={formData.firstName}
                                    label="Full Name"
                                    onChange={(e) => {
                                      handleChange("firstName")(e);
                                      formData.firstName = e.target.value;
                                    }}
                                  />
                                )}
                                {errors["firstName"] &&
                                  touched["firstName"] && (
                                    <span
                                      className="text-error"
                                      style={{ color: "red" }}
                                    >
                                      {errors["firstName"]}
                                    </span>
                                  )}
                              </FormControl>
                              <FormControl
                                variant={myVariant}
                                disabled={editFields ? false : true}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  Last Name
                                </InputLabel>
                                {!editFields && (
                                  <Input
                                    sx={inputStyles}
                                    value={formData.lastName}
                                    disableUnderline
                                  />
                                )}
                                {editFields && (
                                  <OutlinedInput
                                    id="component-outlined"
                                    defaultValue={formData.lastName}
                                    label="Full Name"
                                    onChange={(e) => {
                                      handleChange("lastName")(e);
                                      formData.lastName = e.target.value; // Update the data object
                                    }}
                                  />
                                )}
                                {errors["lastName"] && touched["lastName"] && (
                                  <span
                                    className="text-error"
                                    style={{ color: "red" }}
                                  >
                                    {errors["lastName"]}
                                  </span>
                                )}
                              </FormControl>

                              <FormControl
                                variant={myVariant}
                                disabled={editFields ? false : true}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  Username
                                </InputLabel>
                                {!editFields && (
                                  <Input
                                    sx={inputStyles}
                                    value={formData.username}
                                    disableUnderline
                                  />
                                )}
                                {editFields && (
                                  <OutlinedInput
                                    id="component-outlined"
                                    defaultValue={formData.username}
                                    label="Username"
                                    onChange={(e) => {
                                      handleChange("username")(e);
                                      formData.username = e.target.value; // Update the data object
                                    }}
                                  />
                                )}
                                {errors["username"] && touched["username"] && (
                                  <span
                                    className="text-error"
                                    style={{ color: "red" }}
                                  >
                                    {errors["username"]}
                                  </span>
                                )}
                              </FormControl>

                              <FormControl
                                variant={myVariant}
                                disabled={editFields ? false : true}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  Email
                                </InputLabel>
                                {!editFields && (
                                  <Input
                                    sx={inputStyles}
                                    value={formData.email}
                                    disableUnderline
                                  />
                                )}
                                {editFields && (
                                  <OutlinedInput
                                    id="component-outlined"
                                    defaultValue={formData.email}
                                    label="Email"
                                    onChange={(e) => {
                                      handleChange("email")(e);
                                      formData.email = e.target.value; // Update the data object
                                    }}
                                  />
                                )}
                                {errors["email"] && touched["email"] && (
                                  <span
                                    className="text-error"
                                    style={{ color: "red" }}
                                  >
                                    {errors["email"]}
                                  </span>
                                )}
                              </FormControl>

                              <FormControl
                                variant={myVariant}
                                disabled={editFields ? false : true}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  Phone
                                </InputLabel>
                                {!editFields && (
                                  <Input
                                    sx={inputStyles}
                                    value={formData.formattedPhoneNo}
                                    disableUnderline
                                  />
                                )}
                                {editFields && (
                                  <OutlinedInput
                                    id="component-outlined"
                                    inputProps={{ maxLength: 10 }}
                                    defaultValue={formData.phone.split("+1")[1]}
                                    label="Phone"
                                    onChange={(e) => {
                                      handleChange("phone")(e);
                                      formData.phone = e.target.value;
                                    }}
                                  />
                                )}
                                {errors["phone"] && touched["phone"] && (
                                  <span
                                    className="text-error"
                                    style={{ color: "red" }}
                                  >
                                    {errors["phone"]}
                                  </span>
                                )}
                              </FormControl>
                              <FormControl
                                variant={myVariant}
                                disabled={!editFields}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  Use of Sensitive Information
                                </InputLabel>
                                {!editFields ? (
                                  <Input
                                    sx={inputStyles}
                                    value={formData?.useSensitiveInfo}
                                    disableUnderline
                                  />
                                ) : (
                                  <Select
                                    value={formData?.useSensitiveInfo}
                                    onChange={(e) => {
                                      handleChange("useSensitiveInfo")(e);
                                      formData.useSensitiveInfo =
                                        e.target.value;
                                    }}
                                    label="Use of Sensitive Information"
                                    IconComponent={CustomIcon}
                                  >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                  </Select>
                                )}
                                {errors["useSensitiveInfo"] &&
                                  touched["useSensitiveInfo"] && (
                                    <span
                                      className="text-error"
                                      style={{ color: "red" }}
                                    >
                                      {errors["useSensitiveInfo"]}
                                    </span>
                                  )}
                              </FormControl>
                              <FormControl
                                variant={myVariant}
                                disabled={!editFields}
                                sx={formStyles}
                              >
                                <InputLabel
                                  htmlFor="component-outlined"
                                  sx={inputLabelStyle}
                                  shrink={true}
                                >
                                  User Status
                                </InputLabel>
                                {!editFields ? (
                                  <Input
                                    sx={inputStyles}
                                    value={formData?.status}
                                    disableUnderline
                                  />
                                ) : (
                                  <Select
                                    id="component-outlined"
                                    value={formData?.status}
                                    onChange={(e) => {
                                      handleChange("status")(e);
                                      formData.status = e.target.value;
                                    }}
                                    label="User Status"
                                    IconComponent={CustomIcon}
                                  >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Disabled">
                                      Disabled
                                    </MenuItem>
                                    <MenuItem value="Blocked">Blocked</MenuItem>
                                  </Select>
                                )}
                                {errors["status"] && touched["status"] && (
                                  <span
                                    className="text-error"
                                    style={{ color: "red" }}
                                  >
                                    {errors["status"]}
                                  </span>
                                )}
                              </FormControl>
                            </Box>
                          </>
                        );
                      }}
                    </Formik>
                  </Container>
                </div>

                <div className="card-wrapper">
                  <div className="card-header-stats">
                    <Typography className="card-header-data">Stats</Typography>
                  </div>
                  <div className="card-content">
                    {/* <div className="stats-row">
                      <div className="data-column">
                        <Typography className="data-title">
                          Dwolla Account Status:
                        </Typography>
                        <Typography className="data-value">Verified</Typography>
                      </div>
                    </div> */}
                    <div className="stats-row">
                      <div className="data-column">
                        <Typography className="data-title">
                          Number of Gifts Sent:
                        </Typography>
                        <Typography className="data-value">
                          {formData.giftsSent}
                        </Typography>
                      </div>
                    </div>
                    <div className="stats-row">
                      <div className="data-column">
                        <Typography className="data-title">
                          Number of Gifts Received:
                        </Typography>
                        <Typography className="data-value">
                          {formData.giftsReceived}
                        </Typography>
                      </div>
                    </div>
                    <div className="stats-row">
                      <div className="data-column">
                        <Typography className="data-title">
                          Amount Sent:
                        </Typography>
                        <Typography className="data-value">
                          {formData.amountSent}
                        </Typography>
                      </div>
                    </div>
                    <div className="stats-row">
                      <div className="data-column">
                        <Typography className="data-title">
                          Amount Received:
                        </Typography>
                        <Typography className="data-value">
                          {formData.amountReceived}
                        </Typography>
                      </div>
                    </div>
                    <div className="stats-row">
                      <div className="data-column">
                        <Typography className="data-title">
                          Last Active:
                        </Typography>
                        <Typography className="data-value">
                          {formData?.lastActive
                            ? formatDate(formData.lastActive)
                            : "--"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
