import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { StateContext } from "../../Context/StateContext";
import { CSVLink } from "react-csv"; // Import CSVLink
import "./Topbar.scss";
import { useNavigate } from "react-router-dom";
const TopBar = ({
  header,
  showButton,
  onButtonClick,
  leftIcon,
  onUpdateButtonClick,
  onExportButtonClick,
  prepareDataForExport,
  preparecsv,
  addAdmin,
  saveButton,
  handleClickSave,
  fname
}) => {
  const navigate = useNavigate();
  const { topbarHeader, exportData } = useContext(StateContext);
  console.log({topbarHeader})
  const addAdminClick = () => {
    navigate("/admin-users/add-admin");
  };
  return (
    <div className="top-bar">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="top-bar-heading">
              <p>
                {leftIcon}
                {topbarHeader}
              </p>
            </div>
          </Col>
          {exportData && (
            <>
              {addAdmin ? (
                <>
                  <Col xs={5} sm={5} md={4} lg={4} xl={5} className="text-end display-bar">
                    <div className="">
                      {/* Use the CSVLink to generate the download link */}
                      {preparecsv ? (
                          <CSVLink
                            data={prepareDataForExport}
                            filename="admin_data.csv"
                            className="csv-link"
                            asyncOnClick={true}
                            onClick={onExportButtonClick}
                          >
                          <button className="top-bar-button">Export</button>
                          </CSVLink>
                      ) : null}
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  {preparecsv ? (
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="text-end"
                    >
                      <div className="">
                          <CSVLink
                            data={prepareDataForExport}
                            filename={fname === "gift" ? "gift_data.csv" : fname === "moderation" ? "moderation_data.csv" : "user_data.csv"}
                            className="csv-link"
                            asyncOnClick={true}
                            onClick={onExportButtonClick}
                          >
                             <button className="top-bar-button">
                            Export
                            </button>
                          </CSVLink>
                      </div>
                    </Col>
                  ) : null}
                </>
              )}
            </>
          )}
          {addAdmin ? (
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              style={{ marginLeft: "-17px" }}
            >
              <button className="addAdminBtn" onClick={addAdminClick}>
                Add User
              </button>
            </Col>
          ) : null}
          {saveButton ? (
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
              <button className="saveBtn" onClick={handleClickSave}>Save</button>
            </Col>
          ) : null}
        </Row>
      </Container>
    </div>
  );
};
export default TopBar;
