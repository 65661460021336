import React, { useState } from "react";
const Appcontext = React.createContext({})

const AppProvider = ({children}) => {
  const [token, settoken] = useState("");
  return(
    <Appcontext.Provider value={{ token, settoken }}>
      {children}
    </Appcontext.Provider>
  )
}
export { Appcontext, AppProvider }