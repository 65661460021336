import React, {useEffect, useState} from "react";
import { Box } from "@mui/material";
import { Container, Row, Col } from 'react-bootstrap';
import SimpleCard from "../../components/SimpleCard/SimpleCard";
import "./Gift.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import { useContext } from "react";
import { StateContext } from "../../Context/StateContext";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { ToastContainer } from "react-toastify";

const Gift = () => {
  const [open, setOpen] = React.useState(false);
  const [preparecsv] = useState(false);
  let { setTopbarHeader, topbarHeader} = useContext(StateContext);
  const [giftHeight] = useState("gift");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    userName: "",
    email: "",
    phone: "",
    verifiedUser: "",
    giftsSent: 0,
    giftsReceived: 0,
    amountReceived: "",
    balance: "",
    lastActive: "",
  });

  function formatDate(inputDateStr) {
    let inputDate = new Date(inputDateStr);
    if (isNaN(inputDate.getTime()) || !inputDateStr) {
      const randomDate = new Date(
        Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000
      );
  
      inputDate = randomDate;
    }
  
    const year = inputDate.getFullYear().toString();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const hours = (inputDate.getHours() % 12 || 12).toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";
  
    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;
  
    return formattedDate;
  }
  
  
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.userData) {
      const giftData = location.state.userData;
      document.title =  `${giftData.recipient}- Bar Friender`;
      topbarHeader = "Gift";
      setTopbarHeader(`${topbarHeader} / From ${giftData?.sender || ''} to ${giftData?.recipient || ''} on ${giftData?.dateSent?.split(',')[0].trim() || ''}`);
      setFormData({
        giftID: giftData?.giftID || "--",
        amount: giftData?.giftAmount || "--",
        sender: giftData?.sender || "--",
        recipient: giftData?.recipient || "--",
        privacySetting: giftData?.privacySetting || "--",
        dateSent: formatDate(giftData?.createdAt) || "--",
        note: giftData?.giftNote || "--",
        gif : giftData?.giftGIF || "--",
        recipientTransactionID : giftData?.recipientTransactionID || "--",
        senderTransactionID : giftData?.senderTransactionID || "--",
        senderData: giftData?.senderData,
        recipientData: giftData?.recipientData
      });
    }
  }, [location.state]);


  const handleClickSender = () => {
    setOpen(true);
  }

  const handleClose = (value) => {
    setOpen(false);
  };

  const onMenuClick = (number) => {
    if (number === "first"){
      navigate(`/app-users/user/${formData?.senderData?._id}`, { state: {
        reportedByData: formData?.senderData}
      })
    } 
    if (number === "second"){
      navigate(`/app-users/user/${formData?.recipientData?._id}`, { state: {
        sourceAccountData: formData?.recipientData}
      })
    }
}



  return (
    <>
    <div className="home-container">
       <ToastContainer />
        <Navbar />
    
      <div className="content-container">
          <Sidebar newHeight={giftHeight}/>
        <div className="main-content">
          <div className="topbar-container">
           {/* {!isSignInPage && <TopBar header="App Users" /> } */}
          </div>
          <div className="main-content">
    <div className="topbar-container">
          <Topbar preparecsv={preparecsv}/>
          </div>
    <Box width='100%'>
      <Container className="mt-5">
        <Row>
          <Col md={6} className="mb-3 d-flex justify-content-end">
            <SimpleCard heading='Gift ID' data={formData.giftID}/>
          </Col>
          <Col md={6} className="mb-3 d-flex justify-content-start">
            <SimpleCard heading='Gift Amount' data={formData.amount}/>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={6} className="mb-3 d-flex justify-content-end">
            <SimpleCard heading='Sender' data={formData.sender} icon={true} onMenuClick={() => onMenuClick("first")}/>
          </Col>
          <Col md={6} className="mb-3 d-flex justify-content-start">
            <SimpleCard heading='Recipient' data={formData.recipient} icon={true} onMenuClick={() => onMenuClick("second")}/>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={6} className="mb-3 d-flex justify-content-end">
            <SimpleCard heading='Sender Transaction ID' data={formData.senderTransactionID} icon={true}/>
          </Col>
          <Col md={6} className="mb-3 d-flex justify-content-start">
            <SimpleCard heading='Recipient Transaction ID' data={formData.recipientTransactionID} icon={true}/>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={6} className="mb-3 d-flex justify-content-end">
            <SimpleCard heading='Note' data={formData.note} />
          </Col>
          <Col md={6} className="mb-3 d-flex justify-content-start">
            <SimpleCard heading='Privacy Setting' data={formData.privacySetting}/>
          </Col>
        </Row>

        <Row className="justify-content-left">
          <Col md={6} className="mb-3 d-flex justify-content-end" style={{paddingBottom: "196px"}}>
            <SimpleCard heading='Date Sent' data={formData.dateSent}/>
          </Col>
          <Col md={6} className="mb-3 d-flex justify-content-start">
            <SimpleCard heading='GIF' data={
              <Box>
                <img src={formData.gif} alt="pic"/>
              </Box>
            } />
          </Col>
        </Row>
      </Container>
    </Box>
    </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Gift;
