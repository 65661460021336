import React, { useState } from "react";
import { Table, Container, Row, Col, Form, Button } from "react-bootstrap";
import "./CustomTable.scss";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Tab from "../Tabs/Tab";

import {
  DataGrid,
  GridToolbarFilterButton,
  gridClasses,
} from "@mui/x-data-grid";

import { alpha, styled } from "@mui/material/styles";

const ODD_OPACITY = 0.2;


const CustomTable = ({
  columns,
  data,
  context,
  onRowClick,
  pageNumber,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  totalRecords,
  labels,
  changeTab,
  tableHeight,
  userFilter,
  topPadding,
  tabWidth,
  descendingOrder,
  extraOptions,
  icon,
  handleUpIcon,
  handleDownIcon,
  loading = false
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // const [showFilterPanel, setShowFilterPanel] = useState(false);
  const handleRowClick = (rowData) => {
    if (onRowClick) {
      onRowClick(rowData);
    }

    if (context === "flagitem") {
      navigate(`/flag`, { state: { userData: rowData } });
    } else if (context === "user") {
      navigate(`/app-users/user/${rowData._id}`, {
        state: { userData: rowData },
      });
    } else if (context === "gift") {
      navigate(`/gifts/gift/${rowData.giftID}`, {
        state: { userData: rowData },
      });
    } else if (context === "admin") {
      navigate(`/admin-users/update-admin/${rowData._id}`, {
        state: { userData: rowData },
      });
    } else if (context === "moderation") {
      navigate(`/moderation/moderation-user/${rowData._id}`, {
        state: { userData: rowData },
      });
    }
  };

  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   event.preventDefault();
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleFClick = (event) => {
    event.preventDefault();
    setFilterPanelOpen(!isFilterPanelOpen);
  };

  // const handleNextPage = () => {
  //   const newPage = pageNumber + 1;
  //   onPageChange(newPage);
  // };

  // const handlePreviousPage = () => {
  //   if (pageNumber > 0) {
  //     const newPage = pageNumber - 1;
  //     onPageChange(newPage);
  //   }
  // };

  // const handleRowsPerPageChange = (event) => {
  //   onRowsPerPageChange(event.target.value);
  // };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    onPageChange(1);
  };

  const dataWithIds = data?.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  const filteredData = dataWithIds?.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  // const startRecord =
  //   rowsPerPage * (pageNumber > 0 ? pageNumber - 1 : pageNumber) + 1;
  // const endRecord =
  //   pageNumber * rowsPerPage <= totalRecords
  //     ? pageNumber * rowsPerPage
  //     : totalRecords;

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <Form className="mb-3">
              <Form.Group as={Row} controlId="search">
                <Col sm={6} md={2}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    style={{
                      border: "0.01px solid #75777f",
                      borderRadius: "5px",
                    }}
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
                <Col sm={4} md={6}></Col>
                {/* <Col sm={4} md={4}>
                  {showFilterBtn ? (
                    <>
                      <div className="filter-button-top">
                        <button className="btn-filter" onClick={toggleFilterPanel}>
                          Filter
                        </button>
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={isFilterPanelOpen}
                        onClose={() => setFilterPanelOpen(false)}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        
                      </Menu>
                    </>
                  ) : null}
                </Col> */}
              </Form.Group>
            </Form>
            {labels && (
              <Tab labels={labels} changeTab={changeTab} tabWidth={tabWidth} />
            )}
            <div
              style={{
                width: "100%",
                paddingTop: topPadding ? "50px" : "22px",
              }}
            >
              <StripedDataGrid
                style={{
                  height: topPadding ? "700px" : "679px",
                  width: tableHeight ? "40%" : "100%",
                  border: "none",
                  marginTop: "-70px",
                }}
                loading={loading}
                rows={
                  descendingOrder
                    ? filteredData?.sort((a, b) => b.id - a.id)
                    : filteredData
                }
                columns={columns?.map((column) => ({
                  field: column.id,
                  headerName: column.label,
                  flex: 1,
                  minWidth: 210,
                  renderCell: (params) => (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {column.id === "userStatus" ? (
                        <Button variant="contained" className="btn-table">
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              letterSpacing: "0.1px",
                              textAlign: "center",
                            }}
                          >
                            {params.value}
                          </span>
                        </Button>
                      ) : column.format ? (
                        column.format(params.value)
                      ) : (
                        params.value
                      )}
                    </div>
                  ),
                }))}
                components={{
                  Toolbar: () => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {!userFilter ? (
                        <button className="btn-filter">
                          <GridToolbarFilterButton
                            onClick={handleFClick}
                            style={{
                              textTransform: "capitalize",
                              color: "#2b5ac7",
                              fontSize: "14px",
                            }}
                          />
                        </button>
                      ) : (
                        <div style={{ marginTop: "30px" }}></div>
                      )}
                    </div>
                  ),
                }}
                onRowClick={(params) => handleRowClick(params.row)}
                page={pageNumber}
                pageSize={rowsPerPage}
                rowCount={totalRecords}
                onPageChange={onPageChange}
                pageSizeOptions={[10, 20, 30, 50]}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                      /* page: 0 // default value will be used if not passed */
                    },
                  },
                }}
                disableColumnMenu
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row> */}
      {/* </Row> */}
    </Container>
  );
};

export default CustomTable;
