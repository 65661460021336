import React, { useState, useContext, useEffect } from "react";
import CustomTable from "../../components/table/CustomTable";
import TopBar from "../../components/topbar/Topbar";
import "./Moderation.scss";
import { StateContext } from "../../Context/StateContext";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { GetAllModeration, GetModerationsByReportStatus, setFilteredModerations } from "../../Redux/slices/ModerationSlice";

const Moderation = () => {
  const dispatch = useDispatch();
  const [preparecsv] = useState(true);
  const [allModeration, setAllModeration] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [exportModerationData, setExportModerationData] = useState([]);
  const { setTopbarHeader, setExportData } = useContext(StateContext);
  const [moderationFile] = useState("moderation");
  const [descendingOrder] = useState(true);
  const [columns, setColumns] = useState([
    { id: "appUser", label: "App User", minWidth: 170 },
    { id: "reportType", label: "Type of Report", minWidth: 170 },
    { id: "reason", label: "Reason for Report", minWidth: 170 },
    { id: "reportedBy", label: "Reported by", minWidth: 170 },
    { id: "dateReported", label: "Date Reported", minWidth: 170 },
  ]);

  setExportData(true);
  setTopbarHeader("Moderation");
  
  const token = localStorage.getItem("authToken");

  const {
    allModerations,
    filteredModerations,
    allModerationLoading = false,
    filteredModerationLoading = false
  } = useSelector(state => state?.moderation || {});

  function formatDate(inputDateStr) {
    let inputDate = new Date(inputDateStr);
    if (isNaN(inputDate.getTime()) || !inputDateStr) {
      const randomDate = new Date(
        Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000
      );

      inputDate = randomDate;
    }

    const utcDate = new Date(inputDate.getTime() + inputDate.getTimezoneOffset() * 60000);
    const pacificTime = new Date(utcDate.getTime() - 7 * 60 * 60000);


    const year = pacificTime.getFullYear().toString();
    const month = (pacificTime.getMonth() + 1).toString().padStart(2, '0');
    const day = pacificTime.getDate().toString().padStart(2, '0');
    const hours = (pacificTime.getHours() % 12 || 12).toString().padStart(2, '0');
    const minutes = pacificTime.getMinutes().toString().padStart(2, '0');
    const ampm = pacificTime.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} ${ampm} PT`;


    return formattedDate;
  }

  useEffect(() => {
    document.title = "Moderation - Bar Friender";
    fetchData(selectedTab);
  }, [selectedTab, pageNumber, rowsPerPage, searchValue]);

  const fetchData = async (tabIndex) => {
    try {
      if (token) {
        dispatch(setFilteredModerations({
          data: {
            reportItems: []
          },
          results: 0
        }))
        if (searchValue) setPageNumber(1);
        const tabNames = ["Pending Review", "Ignored", "Confirmed"];
        const tabName = tabNames[tabIndex];

        if (tabName === "Ignored" || tabName === "Confirmed") {
          setColumns([
            { id: "appUser", label: "App User", minWidth: 170 },
            { id: "reportType", label: "Type of Report", minWidth: 170 },
            { id: "reason", label: "Reason for Report", minWidth: 170 },
            { id: "reportedBy", label: "Reported by", minWidth: 170 },
            { id: "dateReported", label: "Reported on", minWidth: 170 },
            { id: "adminUser", label: "Admin User", minWidth: 170 },
            { id: "decisionOn", label: "Decision on", minWidth: 170 },
          ]);
        } else {
          setColumns([
            { id: "appUser", label: "App User", minWidth: 170 },
            { id: "reportType", label: "Type of Report", minWidth: 170 },
            { id: "reason", label: "Reason for Report", minWidth: 170 },
            { id: "reportedBy", label: "Reported by", minWidth: 170 },
            { id: "dateReported", label: "Date Reported", minWidth: 170 },
          ]);
        }
        dispatch(GetModerationsByReportStatus({ token, status: tabName ? tabName : "" }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const modifiedData = filteredModerations && filteredModerations?.data?.reportItems && filteredModerations?.data?.reportItems?.map((item) => ({
      ...item,
      _id: item?._id,
      appUser: item?.sourceAccount?.username || "--",
      reportedBy: item?.reportedBy?.username || "--",
      dateReported: formatDate(item?.createdAt) || "--",
      adminUser: item?.location,
      decisionOn: formatDate(item?.lastUpdated) || "--",
      reportedByData: item?.reportedBy,
      sourceAccountData: item?.sourceAccount,
    }));
    setAllModeration(modifiedData);
    setTotalRecords(filteredModerations?.results);
  }, [filteredModerations])

  useEffect(() => {
    dispatch(GetAllModeration({ token }));
  }, [selectedTab]);

  useEffect(() => {
    let filteredData = allModerations?.data || [];
    if (selectedTab === 0) {
      filteredData = filteredData?.filter((report) => report.reportStatus === 'Pending Review');
    }
    if (selectedTab === 1) {
      filteredData = filteredData?.filter((report) => report.reportStatus === 'Ignored');
    }
    if (selectedTab === 2) {
      filteredData = filteredData?.filter((report) => report.reportStatus === 'Confirmed');
    }
    const data = filteredData?.map((report) => {
      let result = {
        "App User": report?.sourceAccount?.username || "--",
        "Type of Report": report.reportType,
        "Reason for Report": report.reason,
        "Reported by": report?.reportedBy?.username || "--",
      };
      if (selectedTab !== 0) {
        result["Reported on"] = formatDate(report?.createdAt) || "--";
        result["Admin User"] = report?.location;
        result["Decision On"] = formatDate(report?.lastUpdated) || "--";
      } else {
        result["Date Reported"] = formatDate(report?.createdAt) || "--";
      }
      return result;
    });
    setExportModerationData(data);
  }, [allModerations])

  // const handleExportToCSV = (event, done) => {
  //   try {
  //     done(true);
  //   } catch (error) {
  //     done(false);
  //   }
  // };
  const handleExportToCSV = (event, done) => {
    try {
      
      const dataToExport = exportModerationData || [];
      
     
      console.log("Data to export:", dataToExport);
      
      done(true); 
    } catch (error) {
      console.error("Error exporting CSV:", error);
      done(false);
    }
  };
  

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };
  return (
    <div className="home-container">
      <Navbar />

      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container"></div>
          <div>
            <div className="main-content">
              <div className="topbar-container">
                <TopBar
                  header="Moderation"
                  onExportButtonClick={handleExportToCSV}
                  prepareDataForExport={exportModerationData}
                  preparecsv={preparecsv}
                  fname={moderationFile}
                />
              </div>

              <div className="table-container">
                <CustomTable
                  columns={columns}
                  data={allModeration}
                  context="moderation"
                  labels={["Pending Review", "Ignored", "Confirmed"]}
                  changeTab={setSelectedTab}
                  pageNumber={pageNumber}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  totalRecords={totalRecords}
                  searchValue={searchValue}
                  handleSearchChange={setSearchValue}
                  descendingOrder={descendingOrder}
                  loading={allModerationLoading || filteredModerationLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moderation;
