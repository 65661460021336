import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./SimpleCard.scss";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function SimpleCard(props) {
  const { heading = "", data = "", styles = {}, icon = false } = props;
  return (
    <Card className="card" style={styles}>
      <CardContent className="card-content">
        <Box display="flex" justifyContent={icon ? "space-between" : ""}>
          <Typography variant="h5" component="div">
            {heading}
          </Typography>
          {icon && (
           <CardActions>
           <OpenInNewIcon sx={{ color: 'blue' }} onClick={props.onMenuClick} className={props.icon ? 'pointer-cursor' : ''} />
         </CardActions>
          )}
        </Box>
        <Typography sx={{ pt: "10px", textAlign: "left" }}>{data}</Typography>
      </CardContent>
    </Card>
  );
}
