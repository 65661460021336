import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Settings as SettingsIcon } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import MessageIcon from "@mui/icons-material/Message";
import "./Sidebar.scss";
import { ModerationCountContext } from "../../Context/ModerationContext";
import profileIcon from "../../assests/images/profile.svg";

const Sidebar = ({ newHeight }) => {
  const { totalModeration } = useContext(ModerationCountContext);
  return (
    <div
      className="sidebar"
      id="customSidebar"
      style={{
        height:
          newHeight === "user"
            ? "1275px"
            : newHeight === "gift"
            ? "950px"
            : "968px",
      }}
    >
      <ul className="nav flex-column">
        <NavLink
          to="/app-users"
          activeClassName="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <img src={profileIcon} alt="profile" />
            </span>
           <b>App Users</b>
          </Button>
        </NavLink>

        <NavLink
          to="/gifts"
          activeClassName="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <CardGiftcardIcon />
            </span>
            <b>Gifts</b>
          </Button>
        </NavLink>
        <NavLink
          to="/moderation"
          activeClassName="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <ImageSearchIcon />
            </span>
            <b>Moderation</b>
            {/* <span className="totalModeration"><b>{totalModeration}+</b></span> */}
          </Button>
        </NavLink>
        <NavLink
          to="/admin-users"
          activeClassName="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <PeopleIcon />
            </span>
           <b>Admin Users</b>
          </Button>
        </NavLink>

        <NavLink
          to="/gift-message-list"
          activeClassName="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <MessageIcon />
            </span>
            <b>Gift Message List</b>
          </Button>
        </NavLink>
        {/* <NavLink
          to="/se"
          activeclassname="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <DashboardIcon />
            </span>
            Dwolla Dashboard
          </Button>
        </NavLink> */}
        <NavLink
          to="/system-settings"
          activeClassName="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <SettingsIcon />
            </span>
           <b>System Settings</b>
          </Button>
        </NavLink>
      </ul>
    </div>
  );
};

export default Sidebar;
