import React, {useEffect} from 'react'
import { Typography, Box } from '@mui/material';
import './NotFound.scss';

export const NotFound = () => {

  useEffect(() => {
    document.title = "404 - Bar Friender";
  }, [])

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <div className='page-not-found'>
        <Typography className='style-404'>
            404
        </Typography>
        <Typography className='page-not-found-text'>
            Page not found
        </Typography>
        <Typography style={{fontWeight: "600"}}>
            --
        </Typography>
      </div>
     </Box>
  )
}
