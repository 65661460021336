import React from "react";
import "./Modal.scss";
import { Button } from "@mui/material";

const Modal = ({ onClose, onConfirm }) => {

  
  return (
    
    <div className='screen'>
      <div className="modal">
        <div className="modalcontent">
          <p className="delete-title">
            Are you sure you want to delete this user?
          </p>
          <div className="modal-buttons">
            <button onClick={onConfirm} className="deletebutton-modal">
              Confirm
            </button>
            <button
              variant={"outlined"}
              className="edit-button-modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
